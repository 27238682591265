<template>
  <VueFinalModal
    class="overflow-auto"
    content-class="modal-dialog modal-dialog-centered modal-md"
    display-directive="show"
  >
    <div class="modal-content rounded-20">
      <div class="modal-header border-0">
        <button
          class="btn-close"
          type="button"
          aria-label="Закрыть"
          title="Закрыть"
          @click="confirm"
        />
      </div>
      <div class="modal-body pt-0">
        <p class="heading-section-4 text-dark fw-normal text-center mb-34">
          Вход в личный кабинет
        </p>
        <AuthorizationComponent
          is-modal
          @login="login"
        />
      </div>
    </div>
  </VueFinalModal>
</template>

<script setup lang="ts">
import { VueFinalModal } from 'vue-final-modal';
import AuthorizationComponent from "~/components/AuthorizationComponent.vue";

const emits = defineEmits(['confirm', 'login']);

//-----METHODS-----\\
function confirm() {
  emits('confirm');
}
function login() {
  confirm();
  emits('login');
}
</script>

<style scoped lang="scss">

</style>